import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import { content } from './content';

const { DynamicContainer, ContainerType, Carousel, CarouselTypes } = UI;

const ServicesSection = ({ rules, reference }) => (
    <DynamicContainer extend={rules.extendMainContainer} as={ContainerType.SECTION}>
        <Carousel
            title={<FormattedMessage id="webAppDevelopmentPage.services.title" />}
            titleAs="h3"
            subtitleAs="h5"
            content={content}
            type={CarouselTypes.SERVICES}
            reference={reference}
            lazyLoaded
            isServices
            containerEventId="carousel-root-web"
        />
    </DynamicContainer>
);

ServicesSection.propTypes = {
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape(),
};

ServicesSection.defaultProps = {
    reference: null,
};

export default ServicesSection;
