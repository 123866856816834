import type { TechnologyCategory } from 'modules/ui';

import { Technologies } from '../../../../images';
import { content as mobile } from '../../../mobile-development-page/components/TechnologiesSection/content';

const webTechnologies = [
    'html5',
    'react',
    'webpack',
    'redux',
    'fela',
    'gatsby',
    'typescript',
    'nodejs',
    'kubernetes',
    'googlecloud',
    'gitlab',
    'nextjs',
] as const;

const web: TechnologyCategory = {
    name: 'careerPage.technologies.web.name',
    items: webTechnologies.map(id => ({
        id,
        title: `webAppDevelopmentPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
};

const androidTechnologies = [
    'androidsdk',
    'coil',
    'grpc',
    'flipper',
    'firebase',
    'androidroom',
    'kotlin',
    'jetpackcompose',
    'kotlincoroutines',
    'koin',
    'graphql',
    'pluto',
    'detekt',
    'leakcanary',
    'stackoverflow',
    'gitlab',
    'retrofit',
    'okhttp',
    'github',
    'git',
    'androidstudio',
    'gradle',
    'junit',
    'mockito',
    'robolectric',
    'jetpack',
    'githubactions',
] as const;

const android: TechnologyCategory = {
    name: 'careerPage.technologies.android.name',
    items: androidTechnologies.map(id => ({
        id,
        title: `careerPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
};

const iosTechnologies = [
    'firebase',
    'swift',
    'realm',
    'googleanalytics',
    'github',
    'crashlytics',
    'fastlane',
    'git',
    'xcode',
] as const;

const ios: TechnologyCategory = {
    name: 'careerPage.technologies.ios.name',
    items: iosTechnologies.map(id => ({
        id,
        title: `careerPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
};

const backendTechnologies = [
    'nodejs',
    'typescript',
    'postgresql',
    'googlecloud',
    'jest',
    'rest',
    'graphql',
    'go',
    'redis',
    'grpc',
] as const;

export const backend: TechnologyCategory = {
    name: 'careerPage.technologies.backend.name',
    items: backendTechnologies.map(id => ({
        id,
        title: `careerPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
} as const;

const designTechnologies = [
    'adobe',
    'figma',
    'googleanalytics',
    'googledocs',
    'keynote',
    'hotjar',
    'notion',
    'participate',
    'blender',
    'spotify',
] as const;

const design: TechnologyCategory = {
    name: 'careerPage.technologies.design.name',
    items: designTechnologies.map(id => ({
        id,
        title: `careerPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
};

const testingTechnologies = [
    'appium',
    'browserstack',
    'cypress',
    'javascript',
    'mitmproxy',
    'newman',
    'postman',
    'qase',
] as const;

const testing: TechnologyCategory = {
    name: 'careerPage.technologies.testing.name',
    items: testingTechnologies.map(id => ({
        id,
        title: `careerPage.technologies.${id}.title`,
        image: Technologies[id],
    })),
};

export const content = {
    mobile,
    web,
    android,
    ios,
    backend,
    design,
    testing,
} as const;
