import type { TRuleWithTheme } from '@ackee/fela';

export const image: TRuleWithTheme = () => ({
    position: 'absolute',
    zIndex: -1,
    width: '80%',
    marginLeft: '-3.3125rem',

    tabletLarge: {
        position: 'relative',
        width: 'calc(100% + 8.5rem)',
        marginTop: '-8rem',
        marginRight: '-4.1875rem',
        marginLeft: '-4.3125rem',
    },
});
