import bg from '../../../../../static/images/loader/background.png';

export const extendMainContainer = {
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        position: 'relative',
    }),
};

export const extendTitle = {
    title: ({ theme: { colors } }) => ({
        color: colors.white,
        gridColumn: 'auto / span 12',
    }),
};

export const extendCarouselContainer = {
    container: ({ theme }) => ({
        paddingTop: '7.5rem',
        paddingBottom: 0,

        borderBottom: `1px solid ${theme.colors.dividerGrey}`,

        tablet: {
            paddingBottom: '9.5rem',
        },
    }),
};

export const title = ({ theme: { colors } }) => ({
    fontSize: '1.125rem',
    marginBottom: '3rem',
    color: colors.blue,

    ':first-of-type': {
        color: colors.fuchsia,
        fontSize: '1.5rem',
    },
});

export const slideContainer = () => ({
    marginBottom: '8.875rem',
});

export const slideTitle = () => ({
    width: '80%',
});
