import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from '@ackee/fela';

import { WorkshopOrder } from 'modules/analysis-page';

import HeroSection from '../../../hero-section';
import * as UI from '../../../ui';
import { InquirySection } from '../../../inquiry';

import ProcessSection from '../ProcessSection';
import BlogSection from '../BlogSection';
import { DevelopmentSection } from '../DevelopmentSection';
import { Offices } from '../../../offices';

import HeroMenu from '../HeroMenu';

import { CaseStudies } from '../../../case-studies';
import { WebServicesSection } from '../../../web-development-page';
import { MobileServicesSection } from '../../../mobile-development-page';
import { carouselContent } from '../../../homepage/content/carouselContent';
import { whyColumns, development } from '../../content';

import * as felaRules from './AppDevelopmentPage.styles';

import HeroImage from './HeroImage';
import { workshopOrderContent } from '../ProcessSection/workshopOrderContent';

const { SectionNavigation, sectionTitles, TitleTypes, DynamicContainer } = UI;

const AppDevelopmentPage = () => {
    const heroRef = React.useRef();
    const whyRef = React.useRef();
    const mobileRef = React.useRef();
    const webRef = React.useRef();
    const backendRef = React.useRef();
    const designRef = React.useRef();
    const officesRef = React.useRef();

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.appDevelopmentPage,
            id: 1,
        },
        whySection: {
            ref: whyRef,
            title: sectionTitles.why.appDevelopmentPage,
            id: 2,
        },
        mobileSection: {
            ref: mobileRef,
            title: sectionTitles.development.mobile,
            id: 3,
        },
        webSection: {
            ref: webRef,
            title: sectionTitles.development.web,
            id: 4,
        },
        designSection: {
            ref: designRef,
            title: sectionTitles.development.design,
            id: 5,
        },
        backendSection: {
            ref: backendRef,
            title: sectionTitles.development.backend,
            id: 6,
        },
        offices: {
            ref: officesRef,
            title: sectionTitles.offices.appDevelopmentPage,
            id: 7,
        },
    };

    const { locale } = useIntl();

    const { rules } = useFelaEnhanced(felaRules);

    return (
        <>
            <HeroSection
                ref={heroRef}
                title={<FormattedMessage id="appDevelopmentPage.hero.title" />}
                titleAs={TitleTypes.H1}
                sectionTitle={<FormattedMessage id="appDevelopmentPage.hero.sectionTitle" />}
                showLogos
                customContent={
                    <>
                        <HeroImage src="app-development/hero/hero.png" alt="appDevelopmentPage.hero.image.alt" />
                        <HeroMenu mobileRef={mobileRef} webRef={webRef} designRef={designRef} backendRef={backendRef} />
                    </>
                }
                extend={{ extendMainTitle: rules.extendMainTitle }}
            />
            <CaseStudies carouselContent={carouselContent[locale]} />
            <HeroSection
                isAppDevelopmentWhy
                ref={whyRef}
                title={<FormattedMessage id="appDevelopmentPage.why.sectionTitle" />}
                columns={whyColumns}
                showColumns
                extend={{ extendMainTitle: rules.extendAppDevelopmentWhyTitle }}
            />
            <DevelopmentSection id="mobile" ref={mobileRef} content={development.mobile} />
            <MobileServicesSection />
            <DevelopmentSection
                extend={{ image: rules.appDevelopmentImage }}
                id="web"
                ref={webRef}
                content={development.web}
            />
            <WebServicesSection />
            <ProcessSection reference={designRef} />
            <DevelopmentSection
                id="backend"
                ref={backendRef}
                content={development.backend}
                extend={{ container: rules.extendBackendContainer }}
            />
            <DynamicContainer isFluid extend={{ container: rules.extendWorkshopOrderContainer }}>
                <WorkshopOrder content={workshopOrderContent} />
            </DynamicContainer>
            <BlogSection />
            <Offices />
            <InquirySection ref={officesRef} />
            <SectionNavigation sections={sections} />
        </>
    );
};

export default AppDevelopmentPage;
