import * as Constants from '../../../../constants';

const ids = [
    Constants.ReferenceCategory.FINANCE,
    'sharingEconomy',
    Constants.ReferenceCategory.E_COMMERCE,
    Constants.ReferenceCategory.DELIVERY,
    // Constants.ReferenceCategory.BLOCKCHAIN,
    // Constants.ReferenceCategory.TRAVEL,
    // Constants.ReferenceCategory.DIGITALIZATION,
];

export const content = ids.map(id => ({
    id,
    serviceName: `mobileAppDevelopmentPage.services.${id}.menuItem`,
    title: `mobileAppDevelopmentPage.services.${id}.title`,
    description: `mobileAppDevelopmentPage.services.${id}.description`,
    list: `mobileAppDevelopmentPage.services.${id}.list`,
}));
