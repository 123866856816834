//  this cannot be imported from dependencies
import React from 'react';
import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { type RulesExtend, useFelaEnhanced } from '@ackee/fela';
import Img from 'gatsby-image';

import type { MessageKey } from 'modules/intl';

import * as felaRules from './Image.rules';

interface ImageProps {
    src: string;
    alt: MessageKey;
    extend?: RulesExtend<typeof felaRules>;
}

export const Image = ({ src, alt, extend }: ImageProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    const { formatMessage } = useIntl();
    const imageAlt = formatMessage({ id: alt });

    const images = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "app-development/development-section" } }) {
                nodes {
                    base
                    relativePath
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 763) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    const match = React.useMemo(
        () => images.allFile.nodes.find(({ relativePath }: { relativePath: string }) => src === relativePath),
        [images, src],
    );

    const {
        childImageSharp: { fluid },
    } = match;

    return (
        <div className={styles.image}>
            <Img alt={imageAlt} fluid={fluid} />
        </div>
    );
};
