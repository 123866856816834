import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import Config from '../../../../config';

import { BlogPosts } from '../../../blog-posts';

const { DynamicContainer, ContainerType } = UI;

const BlogSection = ({ rules, reference }) => {
    return (
        <DynamicContainer extend={rules.extendContainer} ref={reference} isFluid as={ContainerType.SECTION}>
            <BlogPosts department={Config.departments.general} />
        </DynamicContainer>
    );
};

BlogSection.propTypes = {
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape(),
};

BlogSection.defaultProps = {
    reference: null,
};

export default BlogSection;
