export const extendContainer = {
    container: ({ theme: { colors } }) => ({
        borderBottom: `1px solid ${colors.dividerGrey}`,
        paddingTop: '2.125rem',

        tabletLarge: {
            paddingTop: '2.125rem',
        },
    }),
};
