const BASE_WIDTH_REM = 75;
const TABLET_SCALE = 0.7;
const MOBILE_SCALE = 0.5;

export const container = () => ({
    position: 'absolute',
    zIndex: -1,
    transformOrigin: 'top left',
    transform: `scale(${MOBILE_SCALE})`,
    width: `${BASE_WIDTH_REM * MOBILE_SCALE}rem`,
    top: '5rem',
    right: '1rem',

    tablet: {
        transform: `scale(${TABLET_SCALE})`,
        width: `${BASE_WIDTH_REM * TABLET_SCALE}rem`,
        top: '7rem',
        right: '2rem',
    },

    tabletLarge: {
        transform: 'scale(1.0)',
        width: `${BASE_WIDTH_REM}rem`,
        top: '12.0625rem',
        right: '4.0625rem',
    },

    desktopFullHD: {
        top: '25rem',
    },
});
