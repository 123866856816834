import React from 'react';

import { Layout } from '../modules/ui';
import { AppDevelopmentPage } from '../modules/app-development-page';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const AppDevelopmentPagePage = () => {
    return (
        <>
            <Seo pageId={Constants.pages.APP_DEVELOPMENT} />
            <Layout>
                <AppDevelopmentPage />
            </Layout>
        </>
    );
};

export default AppDevelopmentPagePage;
