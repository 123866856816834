import { DeviceTypes } from '../../ui';

import bundestagLogo from '../../../../images/hp/case-studies/logos/bundestag.png';
import bundestagLogoMobile from '../../../../images/hp/case-studies/logos/bundestag_mobile.png';

// * change order here to change order of the slides
export const slideMetas = {
    cs: [
        {
            id: 'nova',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'wwf',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'bundestag',
            logo: {
                desktop: bundestagLogo,
                mobile: bundestagLogoMobile,
            },
            deviceType: DeviceTypes.PHONE,
            isAckee: true,
        },
        {
            id: 'weshare',
            deviceType: DeviceTypes.PHONE,
            isAckee: true,
        },
        {
            id: 'bazos',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'citymove',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'flashsport',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'kosik',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'zonky',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'equabank',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'last',
            deviceType: DeviceTypes.PHONE,
        },
    ],
    en: [
        {
            id: 'nova',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'wwf',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'bundestag',
            logo: {
                desktop: bundestagLogo,
                mobile: bundestagLogoMobile,
            },
            deviceType: DeviceTypes.PHONE,
            isAckee: true,
        },
        {
            id: 'weshare',
            deviceType: DeviceTypes.PHONE,
            isAckee: true,
        },
        {
            id: 'bazos',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'citymove',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'flashsport',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'kosik',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'zonky',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'equabank',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'last',
            deviceType: DeviceTypes.PHONE,
        },
    ],
    de: [
        {
            id: 'bundestag',
            logo: {
                desktop: bundestagLogo,
                mobile: bundestagLogoMobile,
            },
            deviceType: DeviceTypes.PHONE,
            hasPlaceholderReferenceImage: true,
        },
        {
            id: 'weshare',
            deviceType: DeviceTypes.PHONE,
            isAckee: true,
        },
        {
            id: 'wwf',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'tmobile',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'rideguide',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'klicktipp',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'deliveryhero',
            deviceType: DeviceTypes.PHONE,
            hasLocalizedDeviceImage: true,
        },
        {
            id: 'simplifa',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'ventusky',
            deviceType: DeviceTypes.PHONE,
        },
        {
            id: 'last',
            deviceType: DeviceTypes.PHONE,
        },
    ],
};
