import React from 'react';
import { PropTypes } from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { scrollToRef } from '../../../../utils';
import * as UI from '../../../ui';

const { DynamicContainer, Icons } = UI;

const HeroMenu = ({ styles, rules, mobileRef, webRef, designRef, backendRef }) => {
    const scrollToMobile = React.useCallback(() => {
        scrollToRef(mobileRef, 2);
    }, [mobileRef]);

    const scrollToWeb = React.useCallback(() => {
        scrollToRef(webRef, 2.5);
    }, [webRef]);

    const scrollToDesign = React.useCallback(() => {
        scrollToRef(designRef, 3);
    }, [designRef]);

    const scrollToBackend = React.useCallback(() => {
        scrollToRef(backendRef, 3.5);
    }, [backendRef]);

    return (
        <DynamicContainer extend={rules.extendContainer} isFluid>
            <button className={styles.button} type="button" onClick={scrollToMobile}>
                <span className={styles.text}>
                    <FormattedMessage id="sectionNavTitles.development.mobile" />
                </span>
                <Icons.AnchorArrow />
            </button>
            <button className={styles.button} type="button" onClick={scrollToWeb}>
                <span className={styles.text}>
                    <FormattedMessage id="sectionNavTitles.development.web" />
                </span>
                <Icons.AnchorArrow />
            </button>
            <button className={styles.button} type="button" onClick={scrollToDesign}>
                <span className={styles.text}>
                    <FormattedMessage id="sectionNavTitles.development.design" />
                </span>
                <Icons.AnchorArrow />
            </button>
            <button className={styles.button} type="button" onClick={scrollToBackend}>
                <span className={styles.text}>
                    <FormattedMessage id="sectionNavTitles.development.backend" />
                </span>
                <Icons.AnchorArrow />
            </button>
        </DynamicContainer>
    );
};

HeroMenu.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    rules: PropTypes.shape().isRequired,
    mobileRef: PropTypes.shape().isRequired,
    webRef: PropTypes.shape().isRequired,
    designRef: PropTypes.shape().isRequired,
    backendRef: PropTypes.shape().isRequired,
};

export default HeroMenu;
