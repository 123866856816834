import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';

import { content } from './content';

const { DynamicContainer, ContainerType, Carousel, CarouselTypes, TitleTypes } = UI;

const ServicesSection = ({ rules, reference }) => (
    <DynamicContainer extend={rules.extendMainContainer} as={ContainerType.SECTION}>
        <Carousel
            reference={reference}
            titleAs={TitleTypes.H3}
            subtitleAs={TitleTypes.H5}
            title={<FormattedMessage id="mobileAppDevelopmentPage.services.title" />}
            content={content}
            type={CarouselTypes.SERVICES}
            isServices
            containerEventId="carousel-root-mobile"
        />
    </DynamicContainer>
);

ServicesSection.propTypes = {
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape(),
};

ServicesSection.defaultProps = {
    reference: null,
};

export default ServicesSection;
