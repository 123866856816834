export const video = () => ({
    display: 'block',

    top: '2rem',
    right: '-8rem',
    width: '28.625rem',
});

export const extendMainTitle = () => ({
    marginTop: '2.9375rem',
    marginBottom: '3.875rem',
    maxWidth: '30rem',

    tablet: {
        width: '90%',
    },

    desktopLarge: {
        maxWidth: '60.3125rem',
    },
});

export const extendAppDevelopmentWhyTitle = () => ({
    desktopLarge: {
        maxWidth: '60.3125rem',
    },
});

export const appDevelopmentImage = () => ({
    width: '90%',
    marginLeft: '-1rem',
});

export const extendWorkshopOrderContainer = () => ({
    tabletLarge: {
        paddingTop: 0,
        paddingBottom: '6.4375rem',
    },
});

export const extendBackendContainer = () => ({
    paddingTop: 0,
});
