export const container = () => ({});

export const link = {
    textDecoration: 'none',
};

export const tagContainer = {
    marginTop: '1rem',
    marginBottom: '1rem',
};

export const extendTitle = {
    title: ({ theme: { colors } }) => ({
        marginTop: '1.625rem',
        marginBottom: '1.375rem',
        display: 'inline',
        transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
        willChange: 'color',

        ':hover': {
            color: colors.fuchsia,
        },
    }),
};

export const extendText = {
    text: () => ({
        marginTop: '1rem',
    }),
};

export const extendContainer = {
    container: () => ({
        gridRowGap: '3rem',
    }),
};

export const extendSectionTitle = {
    text: ({ theme: { colors } }) => ({
        color: colors.black,
    }),
};
