const sections = ['web', 'mobile', 'general', 'design'];
const ids = [1, 2, 3];

export const blogPosts = Object.fromEntries(
    sections.map(key => [
        key,
        ids.map(id => ({
            id,
            date: `blogPosts.${key}.post${id}.date`,
            title: `blogPosts.${key}.post${id}.title`,
            excerpt: `blogPosts.${key}.post${id}.excerpt`,
            link: `blogPosts.${key}.post${id}.link`,
            image: `${key}/${id}.jpeg`,
        })),
    ]),
);