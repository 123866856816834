import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';

import { content } from './content';

const { DynamicContainer, ContainerType, Technologies } = UI;

const TechnologiesSection = ({ styles, reference }) => (
    <DynamicContainer isFluid ref={reference} as={ContainerType.SECTION}>
        <Technologies content={content} />
        <div className={styles.divider} />
    </DynamicContainer>
);

TechnologiesSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    reference: PropTypes.shape().isRequired,
};

export default TechnologiesSection;
