import Config from '../../../../config';

const image1 = 'app-development/process/process-diagram.jpg';
const image2 = 'app-development/process/ux_design.jpg';
const image3 = 'app-development/process/ui_design.jpg';

export const carouselContent = [
    {
        id: '1',
        navigationTitle: 'appDevelopmentPage.process.carousel.slide1.navigationTitle',
        title: 'appDevelopmentPage.process.carousel.slide1.title',
        description: 'appDevelopmentPage.process.carousel.slide1.description',
        mainImage: image1,
        mainImageAlt: 'appDevelopmentPage.process.carousel.slide1.image.alt',
        linkType: Config.linkTypes.localized,
        link: {
            url: Config.routes['/contact'],
            text: 'appDevelopmentPage.process.carousel.slide1.linkText',
        },
    },
    {
        id: '2',
        navigationTitle: 'appDevelopmentPage.process.carousel.slide2.navigationTitle',
        title: 'appDevelopmentPage.process.carousel.slide2.title',
        description: 'appDevelopmentPage.process.carousel.slide2.description',
        mainImage: image2,
        mainImageAlt: 'appDevelopmentPage.process.carousel.slide2.image.alt',
        linkType: Config.linkTypes.external,
        link: {
            url: 'https://www.ackee.cz/blog/ux-testovani/',
            text: 'appDevelopmentPage.process.carousel.slide2.linkText',
        },
        linkEN: {
            url: 'https://www.ackee.agency/blog/ux-testing/',
            text: 'appDevelopmentPage.process.carousel.slide2.linkText',
        },
    },
    {
        id: '3',
        navigationTitle: 'appDevelopmentPage.process.carousel.slide3.navigationTitle',
        title: 'appDevelopmentPage.process.carousel.slide3.title',
        description: 'appDevelopmentPage.process.carousel.slide3.description',
        mainImage: image3,
        mainImageAlt: 'appDevelopmentPage.process.carousel.slide3.image.alt',
        linkType: Config.linkTypes.localized,
        link: {
            url: Config.routes['/designPage'],
            text: 'appDevelopmentPage.process.carousel.slide3.linkText',
        },
    },
];
