import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as UI from '../../../../../ui';

const { Title, TitleTypes, Text } = UI;

const SingleStep = ({ styles, rules, content }) => (
    <div className={styles.container}>
        <Title as={TitleTypes.H3} extend={rules.extendTitle}>
            <FormattedMessage id={content.title} />
        </Title>
        <Text>
            <FormattedMessage id={content.description} />
        </Text>
    </div>
);

SingleStep.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    content: PropTypes.shape().isRequired,
};

export default SingleStep;
