export const extendStepsContainer = {
    container: ({ theme: { colors } }) => ({
        overflow: 'visible',
    }),
};

export const extendTitle = {
    title: () => ({
        marginTop: 0,
    }),
};

export const extendText = {
    text: () => ({
        marginBottom: '2.5rem',
    }),
};

export const divider = ({ theme: { colors } }) => ({
    height: `0.0625rem`,
    backgroundColor: colors.dividerGrey,
    width: '100%',
    gridColumn: 'auto / span 12',
    marginTop: '8rem',
});

export const link = ({ theme: { colors, transition, fonts } }) => ({
    ...fonts.heroNewBold,
    fontSize: '1.25rem',
    textDecoration: 'none',

    display: 'inline-block',

    borderBottom: `0.125rem solid ${colors.blue}`,

    marginBottom: '6.25rem',

    color: colors.blue,

    transition,
    transitionProperty: 'color',

    ':hover': {
        color: colors.fuchsia,
    },
});
