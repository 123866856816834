export const container = () => ({});

export const extendContainer = {
    container: () => ({
        paddingTop: 0,
        flexGrow: 1,
        tabletLarge: {
            paddingTop: 0,
        },
    }),
};

export const button = ({ theme: { colors } }) => ({
    color: colors.blue,
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
    willChange: 'color',
    marginRight: '43px',
    marginBottom: '43px',

    cursor: 'pointer',

    tabletLarge: {
        marginBottom: 0,
    },

    ':hover': {
        color: colors.fuchsia,
    },
});

export const text = ({ theme: { colors, fonts } }) => ({
    ...fonts.heroNewBold,
    display: 'inline-block',
    fontSize: '1.25rem',
    borderBottom: `2px solid ${colors.blue}`,
    marginRight: '5px',
});
