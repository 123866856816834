export const content = {
    how: {
        title: 'appDevelopmentPage.process.how.title',
        description: 'appDevelopmentPage.process.how.description',
        link: 'appDevelopmentPage.process.how.link',
        designLinkText: 'appDevelopmentPage.process.designLinkText',
        steps: {
            analysis: {
                title: 'appDevelopmentPage.process.how.steps.analysis.title',
                description: 'appDevelopmentPage.process.how.steps.analysis.description',
            },
            ux: {
                title: 'appDevelopmentPage.process.how.steps.ux.title',
                description: 'appDevelopmentPage.process.how.steps.ux.description',
            },
            design: {
                title: 'appDevelopmentPage.process.how.steps.design.title',
                description: 'appDevelopmentPage.process.how.steps.design.description',
            },
        },
    },
};
