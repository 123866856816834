import { TechnologyCategory } from 'modules/ui';

import { Technologies } from '../../../../images';

const columns = ['swift', 'kotlin', 'firebase', 'react', 'flutter', 'stackshare'] as const;

const items: TechnologyCategory['items'] = columns.map(id => ({
    id,
    title: `mobileAppDevelopmentPage.technologies.${id}.title`,
    image: Technologies[id],
}));

items[5].link = 'common.links.stackShare';

export const content: TechnologyCategory = {
    items: [...items],
    sectionTitle: 'mobileAppDevelopmentPage.technologies.sectionTitle',
    name: 'careerPage.technologies.mobile.name',
};
