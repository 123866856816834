import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import * as UI from '../../../ui';
import { useWindowSize } from '../../../../utils/hooks';

import { content } from './content';
import { carouselContent } from './carouselContent';

import WorkshopProcessSlide from '../../../ui/Carousel/WorkshopProcessContent/WorkshopProcessSlide';

import How from './How';

const { DynamicContainer, ContainerType, Carousel, CarouselTypes } = UI;

const ProcessSection = ({ styles, rules, reference, scrollToRef }) => {
    const [mounted, setMounted] = React.useState();
    const [isDesktop, setIsDesktop] = React.useState(undefined);

    const windowSize = useWindowSize();

    React.useEffect(() => {
        setIsDesktop(windowSize.width > 767);
        setMounted(true);
    }, [windowSize]);

    return (
        <DynamicContainer id="design" ref={reference} isFluid as={ContainerType.SECTION} type={ContainerType.GRID}>
            <How content={content.how} />
            <DynamicContainer extend={rules.extendCarouselContainer} columns={12}>
                {mounted ? (
                    <>
                        {isDesktop ? (
                            <Carousel
                                fullscreen
                                extend={rules.extendCarousel}
                                content={carouselContent}
                                type={CarouselTypes.WORKSHOP_PROCESS}
                                lazyLoaded
                                titleAs="h2"
                                containerEventId="carousel-root-design"
                            />
                        ) : (
                            <>
                                {carouselContent.map(item => (
                                    <React.Fragment key={item.id}>
                                        <h3 className={styles.title}>
                                            <FormattedMessage id={item.navigationTitle} />
                                        </h3>
                                        <WorkshopProcessSlide
                                            {...{
                                                ...item,
                                                isActive: true,
                                                reverse: true,
                                                isMoving: false,
                                                transitionDuration: 0.3,
                                                extend: {
                                                    container: rules.slideContainer,
                                                    title: rules.slideTitle,
                                                },
                                            }}
                                        />
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </>
                ) : null}
            </DynamicContainer>
        </DynamicContainer>
    );
};

ProcessSection.propTypes = {
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape().isRequired,
};

export default ProcessSection;
