import bg from '../../../../../static/images/loader/background.png';

export const extendMainContainer = () => ({
    container: ({ theme: { colors } }) => ({
        backgroundColor: colors.darkBlue,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
    }),
});
