import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

import Config from '../../../../../config';

import SingleStep from './SingleStep';

const { DynamicContainer, Title, TitleTypes, TextTypes, Text, LocalizedLink } = UI;

const How = ({ styles, rules, content }) => (
    <>
        <DynamicContainer columns={6}>
            <Title extend={rules.extendTitle} type={TitleTypes.H3} as={TitleTypes.H2}>
                <FormattedMessage id={content.title} />
            </Title>
            <Text extend={rules.extendText} as="p" type={TextTypes.LARGE}>
                <FormattedMessage id={content.description} />
            </Text>
            <LocalizedLink extend={{ link: rules.link }} to={Config.routes['/designPage']}>
                <FormattedMessage id={content.designLinkText} />
            </LocalizedLink>
        </DynamicContainer>
        <DynamicContainer columns={4} extend={rules.extendStepsContainer} startAt={8}>
            {Object.values(content.steps).map(value => (
                <SingleStep key={value.title} content={value} />
            ))}
        </DynamicContainer>
        <div className={styles.divider} />
    </>
);

How.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    content: PropTypes.shape().isRequired,
};

export default How;
