import React, { forwardRef, type ForwardedRef } from 'react';
import { type RulesExtend, useFelaEnhanced } from '@ackee/fela';
import { FormattedMessage } from 'react-intl';

import { DevelopmentSectionContent } from 'modules/app-development-page/content';

import { Image } from './Image';
import * as UI from '../../../ui';

import * as felaRules from './DevelopmentSection.rules';

interface DevelopmentSectionProps {
    id: string;
    content: DevelopmentSectionContent;
    extend: RulesExtend<typeof felaRules>;
}

const { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes, Technologies, FormattedMessageWithLinks } =
    UI;

export const DevelopmentSection = forwardRef(
    ({ content, id = '', extend }: DevelopmentSectionProps, ref: ForwardedRef<HTMLElement>) => {
        const { rules } = useFelaEnhanced(felaRules, { extend });

        return (
            <DynamicContainer
                extend={{ container: rules.container }}
                id={id}
                ref={ref}
                type={ContainerType.GRID}
                as={ContainerType.SECTION}
                isFluid
            >
                <DynamicContainer extend={{ container: rules.extendImageContainer }} columns={5}>
                    <Image extend={{ image: rules.image }} alt={content.title} src={content.image} />
                </DynamicContainer>
                <DynamicContainer columns={6} extend={{ container: rules.extendTextContainer }}>
                    {/* @ts-expect-error */}
                    <Title
                        extend={{ title: rules.extendTitle }}
                        type={TitleTypes.H1_ITALIC_UPPERCASE}
                        as={TitleTypes.H2}
                    >
                        <FormattedMessage id={content.title} />
                    </Title>
                    {/* @ts-expect-error */}
                    <Text extend={{ text: rules.extendText }} type={TextTypes.LARGE}>
                        <FormattedMessageWithLinks id={content.description} />
                    </Text>
                    <Technologies isDevelopmentPage content={content.technologies} />
                </DynamicContainer>
            </DynamicContainer>
        );
    },
);
