import dividerImg from '../../../../../../../static/images/career/hero/divider.svg';

export const container = ({ theme: { colors } }) => ({
    borderLeft: `0.0625rem solid ${colors.lightGrey}`,
    paddingLeft: '1.625rem',
    position: 'relative',
    marginBottom: '3rem',

    '&:before': {
        content: `url(${dividerImg})`,
        position: 'absolute',
        left: -5,
    },
});

export const extendTitle = {
    title: () => ({
        fontSize: '1.5rem',
        marginTop: 0,
        marginBottom: '0.7rem',
    }),
};
