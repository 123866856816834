import { slideMetas } from './slideMetas';

// * main images have to be in static/... folder, whilst device images and author images are in /images/...
// * optimize main images before adding them, these are not optimized by gatsby image yet

const getCarouselContent = items =>
    items.map(item => ({
        id: item.id,
        link: `homepage.caseStudies.${item.id}.link`,
        heading: `homepage.caseStudies.${item.id}.heading`,
        subHeading: `homepage.caseStudies.${item.id}.subHeading`,
        logo: item.logo ?? undefined,
        tags: `homepage.caseStudies.${item.id}.tags`,
        mainImage: `/images/case-studies/assets/img_bg_${item.id}.jpg`,
        deviceImage: `hp/case-studies/devices/img_device_${item.id}${item.hasLocalizedDeviceImage ? '_en' : ''}.png`,
        deviceAlt: `homepage.caseStudies.${item.id}.deviceAlt`,
        deviceType: item.deviceType,
        reference: {
            content: `homepage.caseStudies.${item.id}.reference.content`,
            authorName: `homepage.caseStudies.${item.id}.reference.author.name`,
            authorPosition: `homepage.caseStudies.${item.id}.reference.author.position`,
            authorImage: item.hasPlaceholderReferenceImage
                ? `people/img_person_placeholder.jpg`
                : `people/img_person_${item.id}.jpg`,
            isAckee: Boolean(item.isAckee),
        },
    }));

export const carouselContent = {};

Object.keys(slideMetas).forEach(key => {
    carouselContent[key] = getCarouselContent(slideMetas[key]);
});
