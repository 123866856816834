//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';

const Image = ({ styles, src, fallbackSrc, alt }) => {
    const { formatMessage } = useIntl();
    const imageAlt = formatMessage({ id: alt });

    const { allFile } = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { regex: "^/blog-posts/" } }) {
                nodes {
                    base
                    relativePath
                    relativeDirectory
                    childImageSharp {
                        fluid(quality: 90, cropFocus: SOUTHWEST, maxWidth: 416, maxHeight: 300) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    const findImage = () => {
        let fallback;
        const image = allFile.nodes.find(file => {
            const relativePath = file.relativePath.replace('blog-posts/', '');
            if (fallbackSrc === relativePath) {
                fallback = file;
            }
            return src === relativePath;
        });
        return image || fallback;
    };

    // eslint-disable-next-line
    const match = React.useMemo(findImage, [allFile, src]);

    return (
        <div className={styles.authorImage}>
            <Img alt={imageAlt} fluid={match.childImageSharp.fluid} />
        </div>
    );
};

Image.propTypes = {
    styles: PropTypes.shape().isRequired,
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
    alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
    fallbackSrc: 'placeholder.jpg',
};

export default Image;
