import type { TRuleWithTheme } from '@ackee/fela';

export const container: TRuleWithTheme = () => ({});

export const extendTextContainer: TRuleWithTheme = () => ({
    marginTop: '15rem',
    overflow: 'visible',

    tabletLarge: {
        marginTop: 0,
    },
});

export const extendText: TRuleWithTheme = () => ({
    marginBottom: '8rem',
    fontWeight: 500,
});

export const extendTitle: TRuleWithTheme = () => ({
    marginTop: 0,
    marginBottom: '2.75rem',
});

export const extendImageContainer: TRuleWithTheme = () => ({
    overflow: 'visible',
});

export const image: TRuleWithTheme = () => ({});
