import React from 'react';
import { PropTypes } from 'prop-types';

const Overlay = ({ styles }) => (
    <div className={styles.container}>
        <svg width="1200" height="437" viewBox="0 0 1200 437" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M197 437H187L197 427V437Z" fill="#FF00FF" />
            <path d="M446 437L456 427H446V437Z" fill="#FF00FF" />
            <rect x="197" y="427" width="249" height="10" fill="#FF00FF" />
            <path d="M1035 10H1025L1035 0V10Z" fill="#FF00FF" />
            <path d="M1190 10L1200 0H1190V10Z" fill="#FF00FF" />
            <rect x="1035" width="155" height="10" fill="#FF00FF" />
            <path d="M10 283H0L10 273V283Z" fill="#FF00FF" />
            <path d="M90 283L100 273H90V283Z" fill="#FF00FF" />
            <rect x="10" y="273" width="80" height="10" fill="#FF00FF" />
        </svg>
    </div>
);

Overlay.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Overlay;
