import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage, useIntl } from 'react-intl';

import { blogPosts } from '../../content';

import Img from './Img';

const { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes, Tag } = UI;

const getLocalizedImagePath = (originalPath, locale) => originalPath.replace('/', `/${locale}-`);

const BlogPosts = ({ styles, rules, department }) => {
    const intl = useIntl();
    const { formatMessage, locale } = intl;

    return (
        <DynamicContainer columns={12} extend={rules.extendContainer} type={ContainerType.GRID}>
            <Text as="h2" columns={12} extend={rules.extendSectionTitle} type={TextTypes.SECTION}>
                <FormattedMessage id={`blogPosts.${department}.title`} />
            </Text>
            {blogPosts[department].map(item => {
                const formattedLink = formatMessage({ id: item.link });
                const imgSrc = getLocalizedImagePath(item.image, locale);

                return (
                    <DynamicContainer key={item.id} columns={4}>
                        {/* because we want to track referer */}
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={formattedLink} className={styles.link} target="_blank">
                            <Img src={imgSrc} alt={item.title} />
                            <div className={styles.tagContainer}>
                                <Tag localized extend={rules.extendTag} name={item.date} />
                            </div>
                            <Title extend={rules.extendTitle} type={TitleTypes.H4_LINK}>
                                <FormattedMessage id={item.title} />
                            </Title>
                            <Text as="p" extend={rules.extendText} type={TextTypes.LARGE}>
                                <FormattedMessage id={item.excerpt} />
                            </Text>
                        </a>
                    </DynamicContainer>
                );
            })}
        </DynamicContainer>
    );
};

BlogPosts.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    department: PropTypes.string.isRequired,
};

export default BlogPosts;
